.kenburns-animation {
    transform-origin: center center;
    transition: transform 3s ease-in-out; /* Adjust the transition duration as needed */
  }
  
  .kenburns-animation.active {
    transform: scale(1.2);
  }
  

.custom-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 99;
}

.arrow {
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 99;
}

.arrow.left {
  margin-left: 0;
}

.arrow.right {
  margin-right: 0;
}

/* Adjust the styling as needed */

.zoomed {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.zoom-in-cursor {
  cursor: zoom-in;
}

.zoom-out-cursor {
  cursor: zoom-out;
}
